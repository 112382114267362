import classNames from 'classnames';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

//Ui-kit
import BorderedContainer from 'ui-kit/bordered-container/bordered-container';
import Button from 'ui-kit/button/button';
import IconBackgroundColor from 'ui-kit/icon-background-color';
// Ui-kit: icons
import UserCircleIcon from 'ui-kit/icons/user-circle';

// Interfaces and types
import { ManageGroupCardProps, MembershipInvitationStatus } from 'types/membership';

// Sub-components
import FamilyMember, { formatInvitationStatus } from '../../family-management/family-member';
// Styles
import './manage-group-card.style.scss';

// Main component
const ManageGroupCard = ({
    accountType,
    memberFirstName,
    memberLastName,
    memberInsuranceId,
    memberName,
    email,
    phoneNumber,
    ePostPatientNum,
    showRadioButton,
    inputRadioElement,
    isInvitee,
    invitationStatus,
    accessGrantedDate,
    isSingleAccount,
    isPlanOwner,
    profileEPostNum,
    membershipId,
    hideContactDetails = false,
    className,
    onRemoveMember,
    onLeavePlan,
    onInvitePlan
}: ManageGroupCardProps): ReactElement => {
    const { t } = useTranslation();
    const containerClasses = classNames('manage-group-card', { [`${className}`]: className });

    const handleRemoveAction = (values: Partial<ManageGroupCardProps>) => {
        if (!onRemoveMember) return;
        onRemoveMember({
            ePostPatientNum: values.ePostPatientNum,
            memberName: values.memberName,
            membershipId: values.membershipId
        });
    };

    const handleLeaveAction = (values: Partial<ManageGroupCardProps>) => {
        if (!onLeavePlan) return;
        onLeavePlan({
            ePostPatientNum: values.ePostPatientNum
        });
    };

    const handleInviteAction = (values: Partial<ManageGroupCardProps>) => {
        if (!onInvitePlan) return;
        onInvitePlan({
            holderEPostPatientNumber: values?.ePostPatientNum,
            ePostPatientNum: values?.ePostPatientNum,
            membershipId: values?.membershipId,
            memberFirstName: values?.memberFirstName,
            memberLastName: values?.memberLastName,
            memberInsuranceId: values?.memberInsuranceId || '',
            phoneNumber: values?.phoneNumber,
            email: values?.email,
            accountType: values?.accountType
        });
    };

    function getInvitationStatus(
        isInvitee: boolean | undefined,
        isPlanOwner: boolean | undefined,
        invitationStatus: MembershipInvitationStatus | undefined
    ) {
        if (isPlanOwner) {
            return undefined;
        }

        return isInvitee ? undefined : invitationStatus;
    }

    return (
        <BorderedContainer className={containerClasses}>
            <div className="manage-group-card__body">
                <div className="manage-group-card__member-name">{memberName}</div>
                <div className="manage-group-card__content">
                    <div className="manage-group-card__content__icon-container">
                        <IconBackgroundColor variant="primary" className="manage-group-card__content__icon">
                            <UserCircleIcon />
                        </IconBackgroundColor>
                    </div>
                    <div className="manage-group-card__content__member-info">
                        <FamilyMember
                            name={memberName}
                            invitationStatus={getInvitationStatus(isInvitee, isPlanOwner, invitationStatus)}
                        />
                        {accessGrantedDate && (
                            <div className="manage-group-card__content__access_granted">
                                {t('components.membership.manageGroupCard.grantedOn', { date: accessGrantedDate })}
                            </div>
                        )}
                        <div
                            className={`manage-group-card__content__member-invitation-status family-member__invitation-status ${invitationStatus}`}
                        >
                            {formatInvitationStatus(invitationStatus as MembershipInvitationStatus)}
                        </div>
                        {!isSingleAccount ? (
                            <div className="manage-group-card__content__member-status">{accountType}</div>
                        ) : null}
                        {!hideContactDetails && (email || phoneNumber) && (
                            <div className="manage-group-card__content__member-contact">
                                {email && <span>{email.toLowerCase()}</span>}
                                {phoneNumber && <span>{phoneNumber}</span>}
                            </div>
                        )}
                    </div>

                    {inputRadioElement && showRadioButton && (
                        <div className="manage-group-card__content__radio">{inputRadioElement}</div>
                    )}

                    {onLeavePlan && (
                        <div className="manage-group-card__content__button">
                            <Button
                                label={t('components.membership.manageGroupCard.leavePlanButton')}
                                type="button"
                                variant="text"
                                className="btn-text-blue btn-underline no-min-width"
                                onClick={() =>
                                    handleLeaveAction({
                                        ePostPatientNum
                                    })
                                }
                            />
                        </div>
                    )}
                </div>

                {!isInvitee && !isPlanOwner && (
                    <div className="manage-group-card__content__button">
                        {onRemoveMember && (
                            <Button
                                label={t('components.membership.manageGroupCard.removeMemberButton')}
                                type="button"
                                variant="text"
                                className="btn-text-blue btn-underline no-min-width"
                                onClick={() =>
                                    handleRemoveAction({
                                        ePostPatientNum,
                                        memberName
                                    })
                                }
                            />
                        )}
                        {onInvitePlan && (
                            <Button
                                label={t('components.membership.manageGroupCard.resendInviteButton')}
                                type="button"
                                variant="outline-primary"
                                className="sm-full "
                                onClick={() =>
                                    handleInviteAction({
                                        holderEPostPatientNumber: ePostPatientNum,
                                        ePostPatientNum: ePostPatientNum,
                                        membershipId: membershipId,
                                        memberFirstName: memberFirstName,
                                        memberLastName: memberLastName,
                                        memberInsuranceId: memberInsuranceId || '',
                                        phoneNumber: phoneNumber,
                                        email: email,
                                        accountType: accountType
                                    })
                                }
                            />
                        )}
                    </div>
                )}
            </div>
        </BorderedContainer>
    );
};

export default ManageGroupCard;
